import { Params } from "@angular/router";
import { environment } from "@env";

export const formatUrlJsf = (
	path: string,
	token: string,
	id?: string,
	params?: Params
) => {
	const idParam = id ? `ID=${id}` : "";

	let paramStr: string = "";

	if (params) {
		Object.keys(params).forEach(function (key) {
			paramStr += key + "=" + params[key] + "&";
		});
	}
	return token
		? `${environment.URL_BASEERP}${path}?token=${token}&${idParam}&${paramStr}`
		: `${environment.URL_BASEERP}${path}?${idParam}&${paramStr}`;
};
